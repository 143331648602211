import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./components/Home/Home";
import Privacy from "./components/Privacy/Privacy";
import Support from "./components/Support/Support";
import Terms from "./components/Terms/Terms";
import Redirect from './components/Redirect/Redirect';
import Subscription from './components/Subscription/Subscription';
import Success from './components/Success/Success';
import Canceled from './components/Canceled/Canceled';
import PasswordReset from './components/PasswordReset/PasswordReset';

function App() {
  return (
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/privacy" element={<Privacy />} />
          <Route path="/support" element={<Support />} />
          <Route path="/terms" element={<Terms />} />
          <Route path='/app' element={<Redirect />} />
          <Route path='/subscription' element={<Subscription />} />
          <Route path='/success' element={<Success />} />
          <Route path='/canceled' element={<Canceled />} />
          <Route path='/pass' element={<PasswordReset />} />
        </Routes>
      </BrowserRouter>
  );
}

export default App;
