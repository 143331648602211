import React from "react";
import Footer from "../Footer/Footer";
import Header from "../Header/Header";
import './support.scss';

function Support() {
  return (
    <div className="support">
      <Header />
      <div className="sportsy-info">
        <h1 className="title">Sportsy</h1>
        <h2 className="subtitle">Support</h2>
        <p>Looking for help? Shoot us a message at <a href="mailto:support@sportsy.com">support@sportsy.com</a> &amp; we'll do our best to help you out!
        </p>
      </div>
      <Footer />
    </div>
  );
}

export default Support;
