import React from "react";
import Footer from "../Footer/Footer";
import Header from "../Header/Header";
import soccerBall from '../../assets/home/soccer-ball.png';
import appleStoreBadge from '../../assets/badge/App_Store_Badge.png';
import googlePlayBadge from '../../assets/badge/Google_Play_Badge.png';
import './home.scss';

function Home() {
  return (
    <div style={{width: '100%'}}>
      <Header />
      <div className="content">
        <section>
            <div className="main-section">
                <img className="hero-image" src={soccerBall} alt="Soccer ball" />
                <div className="hero-subcontainer">
                    <div className="hero-text-container">
                        <h1 className="hero-title">WORLD-CLASS<br/>SPORTS TRAINING</h1>
                        <h2 className="hero-subtitle">Train with trusted coaches and athletes, right on your computer or your phone.</h2>
                    </div>
                    <div className="badge-container">
                        <a href="https://apps.apple.com/us/app/sportsy/id1449680688">
                            <img className="apple-badge" src={appleStoreBadge} alt="Apple App Store Badge" />
                        </a>
                        <a href="https://play.google.com/store/apps/details?id=com.sportsy">
                            <img className="google-badge" src={googlePlayBadge} alt="Google Play Store Badge" />
                        </a>
                    </div>
                </div>
            </div>
        </section>
      </div>
      <Footer />
    </div>
  );
}

export default Home;
