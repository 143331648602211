import React, {useEffect, useState} from "react";
import Footer from "../Footer/Footer";
import Header from "../Header/Header";
import './passwordReset.scss';
import { useSearchParams } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import {resetPassword} from '../../services/actions';

function PasswordReset() {
    const [inputPassword, setInputPassword] = useState('');
    const [inputRetypePassword, setInputRetypePassword] = useState('');
    const [passwordMismatchError, setPasswordMismatchError] = useState('');
    let [searchParams] = useSearchParams();
    const dispatch = useDispatch();
    const passwordResetSuccess = useSelector(state => state.passwordResetSuccess);
    const passwordResetError = useSelector(state => state.passwordResetError);
    const token = searchParams.get('token')

    const checkPassword = () => {
        if (inputPassword === inputRetypePassword) {
            dispatch(resetPassword(token, inputPassword))
        } else {
            setPasswordMismatchError('Please make sure your passwords match.')
        }
        
    };

  return (
    <div className="container">
        <Header />
        <div className="password-reset-container">
            <h1 className="title">Password Change</h1>
            <div className="passwordForm">
                <div className="password-content">
                    <label htmlFor="password">Password</label>
                    <input 
                        id="password" 
                        value={inputPassword}
                        type="password"
                        onChange={e => setInputPassword(e.target.value)}
                    />
                </div>
                
                <div className="password-content">
                    <label htmlFor="reset-password">Retype Password</label>
                    <input 
                        id="reset-password" 
                        placeholder="Enter password again" 
                        value={inputRetypePassword} 
                        type="password"
                        onChange={e => setInputRetypePassword(e.target.value)} 
                    />
                    <input type="hidden" name="token" value={token} />
                </div>

                <div className="server-error" style={{color: 'red'}}>{passwordResetError}</div>
                <div className="password-error" style={{color: 'red'}}>{passwordMismatchError}</div>
                <div id="password-success" style={passwordResetSuccess ? {display: 'block', color: 'green'} : {display: 'none'}}>
                    Your password has been changed!
                </div>

                <button 
                    className="submit" 
                    onClick={checkPassword}
                >
                    Submit
                </button>
            </div>
            
        </div>
        <Footer />
    </div>
  );
}

export default PasswordReset;
