import logo from "../../assets/logos/sportsy-logo.svg";
import React from "react";
import './header.scss';

function Header() {
  return (
    <header>
      <div className="header-container">
        <a className="header-logo-container" href="/">
          <img className="header-logo" src={logo} alt="Sportsy Logo" />
        </a>
      </div>
    </header>
  );
}

export default Header;
