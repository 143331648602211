export const GET_CHECKOUT_SESSION_SUCCESS = 'GET_CHECKOUT_SESSION_SUCCESS';
export const GET_CHECKOUT_SESSION_PENDING = 'GET_CHECKOUT_SESSION_PENDING';
export const GET_CHECKOUT_SESSION_FAILED = 'GET_CHECKOUT_SESSION_FAILED';

export const GET_TRAINER_INFO_SUCCESS = 'GET_TRAINER_INFO_SUCCESS';
export const GET_TRAINER_INFO_PENDING = 'GET_TRAINER_INFO_PENDING';
export const GET_TRAINER_INFO_FAILED = 'GET_TRAINER_INFO_FAILED';

export const GET_SUBSCRIPTION_PRODUCTS_SUCCESS = 'GET_SUBSCRIPTION_PRODUCTS_SUCCESS';
export const GET_SUBSCRIPTION_PRODUCTS_PENDING = 'GET_SUBSCRIPTION_PRODUCTS_PENDING';
export const GET_SUBSCRIPTION_PRODUCTS_FAILED = 'GET_SUBSCRIPTION_PRODUCTS_FAILED';

export const PASSWORD_RESET_PENDING = 'PASSWORD_RESET_PENDING';
export const PASSWORD_RESET_SUCCESS = 'PASSWORD_RESET_SUCCESS';
export const PASSWORD_RESET_FAILED = 'PASSWORD_RESET_FAILED';