import React from "react";
import Footer from "../Footer/Footer";
import Header from "../Header/Header";
import './canceled.scss';

function Cancel() {
  const returnToApp = () => {
    window.location.replace('https://www.sportsy.com/app');
    window.close();
  };

  return (
    <div className="container">
      <Header />
      <div className="canceled-container">
        <div className="canceled-content">
            <p className="canceled-title">Order canceled!</p>
            <p className="canceled-text">Click the button below to close this window and return to the Sportsy app. </p>
            <p className="canceled-text"> If you have any questions contact <a href="mailto:support@sportsy.com">support@sportsy.com</a></p>
        </div>

        <button onClick={returnToApp} className="return-to-app">RETURN TO APP</button>
      </div>
      <Footer />
    </div>
  );
}

export default Cancel;
