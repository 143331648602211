import axios from 'axios';
import * as ActionTypes from './actionTypes';

const api = window.location.host === 'www.sportsy.io' ? 'https://apollo.sportsy.io/api/v1/' : process.env.REACT_APP_API_URL;

export const passwordResetPending = (payload) => ({
  type: ActionTypes.PASSWORD_RESET_PENDING,
  text: 'Updating password...',
  payload,
});

export const passwordResetSuccess = (payload) => ({
  type: ActionTypes.PASSWORD_RESET_SUCCESS,
  text: 'Password updated!',
  payload,
});

export const passwordResetFailed = (payload) => ({
  type: ActionTypes.PASSWORD_RESET_FAILED,
  text: 'Password update failed',
  payload,
});

export const resetPassword = (token, password) => (dispatch) => {
  dispatch(
    passwordResetPending({
      passwordResetPending: true,
      passwordResetSuccess: false,
      passwordResetFailed: false,
      passwordResetError: '',
    })
  );

  axios
    .post(api + 'password/change', {token: token, password})
    .then((response) => {
      if (response.status === 200 && response.data.status !== 'failure') {
        dispatch(
          passwordResetSuccess({
            passwordResetPending: false,
            passwordResetSuccess: true,
            passwordResetFailed: false,
          })
        )
      } else {
        dispatch(
          passwordResetFailed({
            passwordResetPending: false,
            passwordResetSuccess: false,
            passwordResetFailed: true,
            passwordResetError: response.data.data,
          })
        )
      }
    })
};

export const getCheckoutSessionPending = (payload) => ({
  type: ActionTypes.GET_CHECKOUT_SESSION_PENDING,
  text: 'Getting checkout session...',
  payload,
});

export const getCheckoutSessionSuccess = (payload) => ({
  type: ActionTypes.GET_CHECKOUT_SESSION_SUCCESS,
  text: 'Got checkout session successfully!',
  payload,
});

export const getCheckoutSessionFailed = (payload) => ({
  type: ActionTypes.GET_CHECKOUT_SESSION_FAILED,
  text: 'Failed to get checkout session',
  payload,
});

export const getCheckoutSession = (subscription, trial_period_days) => (dispatch) => {
  dispatch(
    getCheckoutSessionPending({
      getCheckoutSessionPending: true,
      getCheckoutSessionFailed: false,
      getCheckoutSessionSuccess: false,
      getCheckoutSessionError: '',
    }),
  );

  if (trial_period_days > 0) {
    subscription['trial_period_days'] = trial_period_days
  }

  axios
    .post(api + 'create-checkout-session', {subscription})
    .then((response) => {
      if (response.status === 200 && response.data.status !== 'failure') {
        dispatch(
          getCheckoutSessionSuccess({
            getCheckoutSessionPending: false,
            getCheckoutSessionSuccess: true,
            checkoutSessionData: response.data.checkout_session,
          }),
        );
      } else {
        dispatch(
          getCheckoutSessionFailed({
            getCheckoutSessionPending: false,
            getCheckoutSessionFailed: true,
            getCheckoutSessionSuccess: false,
            getCheckoutSessionError: response.data,
          }),
        );
      }
    });
};

export const getTrainerInfoPending = (payload) => ({
  type: ActionTypes.GET_TRAINER_INFO_PENDING,
  text: 'Getting trainer info...',
  payload,
});

export const getTrainerInfoSuccess = (payload) => ({
  type: ActionTypes.GET_TRAINER_INFO_SUCCESS,
  text: 'Getting trainer info success!',
  payload,
});

export const getTrainerInfoFailed = (payload) => ({
  type: ActionTypes.GET_TRAINER_INFO_FAILED,
  text: 'Failed getting trainer info',
  payload,
});

export const getTrainerInfo = (trainer_id) => (dispatch) => {
  dispatch(
    getTrainerInfoPending({
      getTrainerInfoPending: true,
      getTrainerInfoFailed: false,
      getTrainerInfoSuccess: false,
      trainerInfo: {},
      getTrainerInfoError: '',
    }),
  );

  axios
    .post(api + 'subscription/trainer', {trainer_id})
    .then((response) => {
      if (response.status === 200 && response.data.status !== 'failure') {
        dispatch(
          getTrainerInfoSuccess({
            getTrainerInfoPending: false,
            getTrainerInfoSuccess: true,
            trainerInfo: response.data.data,
          }),
        );
      } else {
        dispatch(
          getTrainerInfoFailed({
            getTrainerInfoPending: false,
            getTrainerInfoFailed: true,
            getTrainerInfoSuccess: false,
            getTrainerInfoError: response.data,
          }),
        );
      }
    });
};

export const getSubscriptionProductsPending = (payload) => ({
  type: ActionTypes.GET_SUBSCRIPTION_PRODUCTS_PENDING,
  text: 'Getting subscription products...',
  payload,
});

export const getSubscriptionProductsSuccess = (payload) => ({
  type: ActionTypes.GET_SUBSCRIPTION_PRODUCTS_SUCCESS,
  text: 'Getting subscription products success!',
  payload,
});

export const getSubscriptionProductsFailed = (payload) => ({
  type: ActionTypes.GET_SUBSCRIPTION_PRODUCTS_FAILED,
  text: 'Failed getting subscription products',
  payload,
});

export const getSubscriptionProducts = (productID) => (dispatch) => {
  dispatch(
    getSubscriptionProductsPending({
      getSubscriptionProductsPending: true,
      getSubscriptionProductsFailed: false,
      getSubscriptionProductsSuccess: false,
      subscriptionProducts: {},
      subscriptionProductsError: '',
    }),
  );

  axios
    .post(api + 'subscription/products', {productID})
    .then((response) => {
      if (response.status === 200 && response.data.status !== 'failure') {
        dispatch(
          getSubscriptionProductsSuccess({
            getSubscriptionProductsPending: false,
            getSubscriptionProductsSuccess: true,
            subscriptionProducts: response.data.subscription_products.data.sort((a, b) => a.unit_amount - b.unit_amount),
          }),
        );
      } else {
        dispatch(
          getSubscriptionProductsFailed({
            getSubscriptionProductsPending: false,
            getSubscriptionProductsFailed: true,
            getSubscriptionProductsSuccess: false,
            subscriptionProducts: response.data,
          }),
        );
      }
    });
};
