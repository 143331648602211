import React, {useEffect, useState} from "react";
import Footer from "../Footer/Footer";
import Header from "../Header/Header";
import './subscription.scss';
import { Dots } from "react-activity";
import "react-activity/dist/Dots.css";
import { useSearchParams } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import {getTrainerInfo, getSubscriptionProducts, getCheckoutSession} from '../../services/actions';

function Subscription() {
    let [searchParams] = useSearchParams();

    const dispatch = useDispatch();
    const trainerInfo = useSelector(state => state.trainerInfo);
    const getTrainerInfoSuccess = useSelector(state => state.getTrainerInfoSuccess);
    const subscriptionProducts = useSelector(state => state.subscriptionProducts);
    const getSubscriptionProductsSuccess = useSelector(state => state.getSubscriptionProductsSuccess);
    const getCheckoutSessionSuccess = useSelector(state => state.getCheckoutSessionSuccess);
    const invoiceUrl = useSelector(state => state.checkoutSessionData.url);

    useEffect(() => {
        let trainerID = searchParams.get('t')
        dispatch(getTrainerInfo(trainerID));
    }, []);

    useEffect(() => {
        if (getTrainerInfoSuccess) {
            dispatch(getSubscriptionProducts(trainerInfo[0].subscription_info[0].subscription_id))
        }
    }, [getTrainerInfoSuccess]);

    useEffect(() => {
        if (getCheckoutSessionSuccess) {
            window.location.replace(invoiceUrl)
        }
    }, [getCheckoutSessionSuccess])

    return (
        <div className="container">
            <Header />

            <div className="product-container">
                {
                    getSubscriptionProductsSuccess ?
                    <div className="subcontainer">
                        <div className="trainer-info">
                            <img className="trainer-img" src={trainerInfo[0].profile_picture} alt="Trainer Profile" />
                            <div>
                                <p className="trainer-name">{trainerInfo[0].name}</p>
                                <p className="trainer-location">{trainerInfo[0].location}</p>
                            </div>
                        </div>

                        <div className="subscription-info">
                            <p className="subscription-info-title">What's included:</p>
                            <ul className="subscription-info-content">
                                {
                                    trainerInfo[0].subscription_info[0].subscription_product_info.map((info, i) => {
                                        return (
                                            <li key={i}>{info}</li>
                                        )
                                    })
                                }
                            </ul>
                        </div>

                        <div className="subscription-products-container">
                            {
                                subscriptionProducts
                                    .filter((product) => product.active)
                                    .map((subProduct, i) =>
                                    <button
                                        onClick={() => dispatch(getCheckoutSession(
                                            {
                                                subscriptionType: subProduct.metadata.subscription_type,
                                                price: subProduct.unit_amount,
                                                subscriptionID: subProduct.id,
                                                customerID: searchParams.get('c'),
                                            },
                                            trainerInfo[0].subscription_info[0].trial_period_days
                                        ))}
                                        key={i}
                                        className="subscription-button"
                                    >
                                        <div
                                            key={i}
                                            style={{
                                                background: subProduct.metadata.subscription_type === 'Monthly' ? '#2A2A2A' : `linear-gradient(to left, #005083, #0B96DF)`,
                                            }}
                                            className="subscription-line"
                                        />
                                        <div className="subscription-button-info">
                                            <p className="subscription-product-title">{subProduct.metadata.subscription_type}<br/>Subscription</p>
                                            <p className="subscription-product-price">${parseFloat(subProduct.unit_amount/100).toFixed(2)}</p>
                                            {
                                                subProduct.metadata.subscription_type === 'Annual' ?
                                                <p className="subscription-product-disclaimer">Best Value - Save $30</p> : null
                                            }
                                        </div>
                                        <div
                                            className="subscription-cta"
                                            style={{
                                                background: subProduct.metadata.subscription_type === 'Monthly' ? '#2A2A2A' : 'linear-gradient(to left, #005083, #0B96DF)',
                                            }}
                                        >
                                            <p className="subscription-cta-text">{trainerInfo[0].subscription_info[0].trial_period_days > 0 ? 'START FREE TRIAL' : 'SUBSCRIBE'}</p>
                                        </div>
                                    </button>
                                )
                            }
                        </div>
                    </div> :
                    <Dots color="#727981" size={32} speed={1} animating={true} />
                }
            </div>
            <Footer />
        </div>
    );
}

export default Subscription;
