import * as ActionTypes from './actionTypes';

const initialState = {
  getCheckoutSessionPending: false,
  getCheckoutSessionFailed: false,
  getCheckoutSessionSuccess: false,
  getCheckoutSessionError: '',
  checkoutSessionData: {},
  getTrainerInfoPending: false,
  getTrainerInfoFailed: false,
  getTrainerInfoSuccess: false,
  trainerInfo: {},
  getTrainerInfoError: '',
  passwordResetPending: false,
  passwordResetSuccess: false,
  passwordResetFailed: false,
  passwordResetError: '',
};

export default function rootReducer(state = initialState, action) {
  switch (action.type) {
    case ActionTypes.GET_CHECKOUT_SESSION_PENDING:
      return {
        ...state,
        getCheckoutSessionPending: action.payload.getCheckoutSessionPending,
        getCheckoutSessionFailed: action.payload.getCheckoutSessionFailed,
        getCheckoutSessionSuccess: action.payload.getCheckoutSessionSuccess,
        getCheckoutSessionError: action.payload.getCheckoutSessionError,
      }
    case ActionTypes.GET_CHECKOUT_SESSION_SUCCESS:
      return {
        ...state,
        getCheckoutSessionPending: action.payload.getCheckoutSessionPending,
        getCheckoutSessionSuccess: action.payload.getCheckoutSessionSuccess,
        checkoutSessionData: action.payload.checkoutSessionData,
      }
    case ActionTypes.GET_CHECKOUT_SESSION_FAILED:
      return {
        ...state,
        getCheckoutSessionPending: action.payload.getCheckoutSessionPending,
        getCheckoutSessionFailed: action.payload.getCheckoutSessionFailed,
        getCheckoutSessionSuccess: action.payload.getCheckoutSessionSuccess,
        getCheckoutSessionError: action.payload.getCheckoutSessionError,
      }
    case ActionTypes.GET_TRAINER_INFO_PENDING:
      return {
        ...state,
        getTrainerInfoPending: action.payload.getTrainerInfoPending,
        getTrainerInfoFailed: action.payload.getTrainerInfoFailed,
        getTrainerInfoSuccess: action.payload.getTrainerInfoSuccess,
        trainerInfo: action.payload.trainerInfo,
        getTrainerInfoError: action.payload.getTrainerInfoError,
      }
    case ActionTypes.GET_TRAINER_INFO_SUCCESS:
      return {
        ...state,
        getTrainerInfoPending: action.payload.getTrainerInfoPending,
        getTrainerInfoSuccess: action.payload.getTrainerInfoSuccess,
        trainerInfo: action.payload.trainerInfo,
      }
    case ActionTypes.GET_TRAINER_INFO_FAILED:
      return {
        ...state,
        getTrainerInfoPending: action.payload.getTrainerInfoPending,
        getTrainerInfoFailed: action.payload.getTrainerInfoFailed,
        getTrainerInfoSuccess: action.payload.getTrainerInfoSuccess,
        getTrainerInfoError: action.payload.getTrainerInfoError,
      }
    case ActionTypes.GET_SUBSCRIPTION_PRODUCTS_PENDING:
      return {
        ...state,
        getSubscriptionProductsPending: action.payload.getSubscriptionProductsPending,
        getSubscriptionProductsFailed: action.payload.getSubscriptionProductsFailed,
        getSubscriptionProductsSuccess: action.payload.getSubscriptionProductsSuccess,
        subscriptionProducts: action.payload.subscriptionProducts,
        subscriptionProductsError: action.payload.subscriptionProductsError,
      }
    case ActionTypes.GET_SUBSCRIPTION_PRODUCTS_SUCCESS:
      return {
        ...state,
        getSubscriptionProductsPending: action.payload.getSubscriptionProductsPending,
        getSubscriptionProductsSuccess: action.payload.getSubscriptionProductsSuccess,
        subscriptionProducts: action.payload.subscriptionProducts,
      }
    case ActionTypes.GET_SUBSCRIPTION_PRODUCTS_FAILED:
      return {
        ...state,
        getSubscriptionProductsPending: action.payload.getSubscriptionProductsPending,
        getSubscriptionProductsFailed: action.payload.getSubscriptionProductsFailed,
        getSubscriptionProductsSuccess: action.payload.getSubscriptionProductsSuccess,
        subscriptionProducts: action.payload.subscriptionProducts,
      }
    case ActionTypes.PASSWORD_RESET_PENDING:
      return {
        ...state,
        passwordResetPending: action.payload.passwordResetPending,
        passwordResetSuccess: action.payload.passwordResetSuccess,
        passwordResetFailed: action.payload.passwordResetFailed,
        passwordResetError: action.payload.passwordResetError,
      }
    case ActionTypes.PASSWORD_RESET_SUCCESS:
      return {
        ...state,
        passwordResetPending: action.payload.passwordResetPending,
        passwordResetSuccess: action.payload.passwordResetSuccess,
        passwordResetFailed: action.payload.passwordResetFailed,
      }
    case ActionTypes.PASSWORD_RESET_FAILED:
      return {
        ...state,
        passwordResetPending: action.payload.passwordResetPending,
        passwordResetSuccess: action.payload.passwordResetSuccess,
        passwordResetFailed: action.payload.passwordResetFailed,
        passwordResetError: action.payload.passwordResetError,
      }
    default:
      return state
  }
}