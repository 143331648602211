import React, {useState} from "react";
import Footer from "../Footer/Footer";
import Header from "../Header/Header";
import './success.scss';

function Success() {
    const returnToApp = () => {
        window.location.replace('https://www.sportsy.com/app');
        window.close();
    };

    return (
    <div className="container">
        <Header />
        <div className="success-container">
            <div className="success-content">
                <p className="success-title">You're all set!</p>
                {/* <p className="success-text">
                    A receipt has been emailed to {getEmailAddress}.
                </p> */}
                <p className="success-text">
                    Click the button below to close this window and return to the Sportsy app.
                </p>
                <p className="success-text">
                    If you have any questions or issues with your subscription contact <a href="mailto:support@sportsy.com">support@sportsy.com</a>
                </p>
            </div>

            <button onClick={returnToApp} className="return-to-app">RETURN TO APP</button>
        </div>
        <Footer />
    </div>
  );
}

export default Success;
