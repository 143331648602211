function Redirect() {
    window.setTimeout(function(){
        let landingLink = "/";  // default to home
        const userAgent = navigator.userAgent.toLowerCase();
        const is_iphone = userAgent.indexOf("iphone") >= 0;
        const is_ipad = userAgent.indexOf("ipad") >= 0;
        const is_android = userAgent.indexOf("android") >= 0;
        if (is_iphone || is_ipad) {
            landingLink = "https://apps.apple.com/us/app/sportsy/id1449680688";
        } else if (is_android) {
            landingLink = "https://play.google.com/store/apps/details?id=com.sportsy&hl=en_US&gl=US";
        }
        window.location.replace(landingLink); // avoid history
    }, 1);
    return null;
}

export default Redirect;
