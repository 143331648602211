import React, { useState } from "react";
import { Link } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import FacebookIcon from '@mui/icons-material/Facebook';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import InstagramIcon from '@mui/icons-material/Instagram';
import './footer.scss'

function Footer() {
  const [getDate] = useState(new Date().getFullYear());

  return (
    <footer>
      <div className="footer-info">
        <div className="footer-container">
          <div className="footer-link-container">
            <a className="footer-link" href="/support">Support</a>
            <a className="footer-link" href="/terms">Terms of Use</a>
            <a className="footer-link" href="/privacy">Privacy Policy</a>
          </div>
          <div className="footer-social-container">
            <Link href="https://www.facebook.com/pages/Sportsy/168410580010141"
                target="_blank"
                rel="noreferrer">
                <IconButton style={{width: 50, height: 50, backgroundColor: 'rgba(13,13,13,.5)'}} aria-label="facebook-link">
                  <FacebookIcon style={{color: 'white'}} />
                </IconButton>
              </Link>
              <Link href="https://www.linkedin.com/company/training-labs/about/?viewAsMember=true"
                target="_blank"
                rel="noreferrer">
                <IconButton style={{width: 50, height: 50, backgroundColor: 'rgba(13,13,13,.5)'}} aria-label="linkedin-link">
                  <LinkedInIcon style={{color: 'white'}} />
                </IconButton>
              </Link>
              <Link href="https://www.instagram.com/sportsy/"
                target="_blank"
                rel="noreferrer">
                <IconButton style={{width: 50, height: 50, backgroundColor: 'rgba(13,13,13,.5)'}} aria-label="instagram-link">
                  <InstagramIcon style={{color: 'white'}} />
                </IconButton>
              </Link>
            </div>
          </div>
          <div className="copyright">
            <p>&copy; <span className="date">{getDate}</span> Training Labs Inc.</p>
          </div>
        </div>
      </footer>
  );
}

export default Footer;
